<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <ag-notify />
  </v-app>
</template>

<script>
import AgNotify from 'src/components/common/AgNotify'

export default {
  name: 'DefaultLayout',
  components: {
    AgNotify,
  },
}
</script>
